import { Injectable } from "@angular/core";
import { createClient } from "@sanity/client";

@Injectable({
  providedIn: "root",
})
export class SanityService {
  private sanityClient = createClient({
    projectId: "wrlr8633",
    dataset: "production",
    useCdn: false, // set to `false` to bypass the edge cache
    apiVersion: "2023-05-03", // use current date (YYYY-MM-DD) to target the latest API
  });

  private sanityData: any;

  constructor() {}

  fetchData(): Promise<any> {
    return this.sanityClient.fetch('*[identifier == "confirmation"]{..., section->{slug}, device_type->{slug, name}, conditions[]{...}, brand->{slug}, device_types[]{..., device_type->{slug}}}');
  }

  setData(data: any): void {
    this.sanityData = data;
  }

  getData(): any {
    return this.sanityData;
  }
}
