import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom, tap } from "rxjs";
import { environment } from "../../../environments/environment.development";

@Injectable()
export class OrdersService {
  constructor(private http: HttpClient) {}

  public async createOrder(order: any) {
    const response = await lastValueFrom(this.http.post<any>(environment.apigateway + `/orders/`, order));
    return response;
  }

  public async getOrderByIdAndToken(orderId: string, token: string) {
    const response = await lastValueFrom(this.http.get<any>(environment.apigateway + `/orders/find-by-id-and-token?id=${orderId}&token=${token}`));

    return response;
  }
}
