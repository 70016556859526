<section class="confirmation_page skeleton shipping-label">
  <div class="step-description">
    <div class="left-side">
      <!-- Skeleton for Confirmation Header -->
      <div class="header-skeleton">
        <p-skeleton
          width="60%"
          height="20px"
        ></p-skeleton>
        <p-skeleton
          width="80%"
          height="20px"
          [style]="{ 'margin-top': '8px' }"
        ></p-skeleton>
      </div>

      <!-- Skeleton for Stepper -->
      <div class="stepper-method tw-w-full">
        <p-skeleton
          width="100%"
          height="50px"
          [style]="{ 'margin-top': '16px' }"
        ></p-skeleton>
        <p-skeleton
          width="100%"
          height="50px"
          [style]="{ 'margin-top': '16p' }"
        ></p-skeleton>
        <p-skeleton
          width="100%"
          height="50px"
          [style]="{ 'margin-top': '16p' }"
        ></p-skeleton>
      </div>
    </div>

    <div class="right-side">
      <div class="order-details">
        <p-skeleton
          width="100%"
          height="30px"
        ></p-skeleton>
        <p-skeleton
          width="100%"
          height="30px"
          [style]="{ 'margin-top': '8px' }"
        ></p-skeleton>
        <p-skeleton
          width="100%"
          height="30px"
          [style]="{ 'margin-top': '8px' }"
        ></p-skeleton>
      </div>

      <div class="more-info-wrapper">
        <div class="more-info">
          <div class="more-info-container">
            <div class="dropdown-info-skeleton">
              <p-skeleton
                width="100%"
                height="20px"
                [style]="{ 'margin-top': '16p' }"
              ></p-skeleton>
              <p-skeleton
                width="100%"
                height="20px"
                [style]="{ 'margin-top': '8px' }"
              ></p-skeleton>
              <div class="line-container">
                <div class="line"></div>
              </div>
              <p-skeleton
                width="100%"
                height="20px"
                [style]="{ 'margin-top': '8px' }"
              ></p-skeleton>

              <div class="line-container">
                <div class="line"></div>
              </div>
            </div>
            <div class="need-more-info-skeleton">
              <p-skeleton
                width="80%"
                height="20px"
                [style]="{ 'margin-top': '16p' }"
              ></p-skeleton>
              <div class="main-wrapper-skeleton">
                <p-skeleton
                  width="90%"
                  height="20px"
                  [style]="{ 'margin-top': '8px' }"
                ></p-skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
